import fulfillmentPickupGroupPickingPage from './fulfillment/pickup/fulfillmentPickupGroupPickingPage';
import fulfillmentPickupDetailsPage from './fulfillment/pickup/fulfillmentPickupDetailsPage';
import fulfillmentPickupPackingPage from './fulfillment/pickup/fulfillmentPickupPackingPage';
import fulfillmentPickupPickingPage from './fulfillment/pickup/fulfillmentPickupPickingPage';

import fulfillmentAllShipmentsGridPage from './fulfillment/ship/fulfillmentAllShipmentsGridPage';
import fulfillmentShipGroupPickingPage from './fulfillment/ship/fulfillmentShipGroupPickingPage';
import fulfillmentShipDetailsPage from './fulfillment/ship/fulfillmentShipDetailsPage';
import fulfillmentShipPackingPage from './fulfillment/ship/fulfillmentShipPackingPage';
import fulfillmentShipPickingPage from './fulfillment/ship/fulfillmentShipPickingPage';

import backOrderGridPage from './order/backOrderGridPage';
import orderDetailsPage from './order/orderDetailsPage';

import purchaseOrderPrintPage from './purchaseOrder/purchaseOrderPrintPage';

import justAddingPage from './receiveInventory/justAddingPage';

import printUpdate from './printUpdate';

import patchDEV6289 from './patch-DEV-6289';

export default {
    backOrderGridPage,
    fulfillmentAllShipmentsGridPage,
    fulfillmentPickupGroupPickingPage,
    fulfillmentPickupDetailsPage,
    fulfillmentPickupPackingPage,
    fulfillmentPickupPickingPage,
    fulfillmentShipGroupPickingPage,
    fulfillmentShipDetailsPage,
    fulfillmentShipPackingPage,
    fulfillmentShipPickingPage,
    justAddingPage,
    orderDetailsPage,
    purchaseOrderPrintPage,
    printUpdate,
    patchDEV6289
};
