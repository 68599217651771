import Header from './components/Header';

export default {
    name: 'purchaseOrderPrintPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'purchaseOrderPrint';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps,
                templateProps: {
                    header: Header,
                },
            });

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
