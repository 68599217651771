export default {
    name: 'printUpdate',
    before: [],
    after: [],
    setup: (app) => {
        app.routes.addMany({
            pickupPackingSlipPrint: {
                path: `/fulfillment-pickup/packing-slip/:id`,
                title: `Fulfillments Pickup Print`,
                permission: ['fulfillments.show'],
                breadcrumbConfig: {
                    name: `Fulfillments Pickup Print`,
                },
                props: () => ({}),
                loader: () => import('@skunexus/pickup-packing-slip-print'),
            },
        });
    },
};
